'use client';

import { clsx } from 'clsx';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';

import { ITopNavigationLeftMenusDynamicZone, ITopNavigationRightMenusDynamicZone } from '@squadnet/frontend-cms-gql';
import {
  AtomsButton,
  AtomsSvgIcon,
  IconChevronDown,
  NavigationLink,
  OrganismsMenuGroup,
  Popper,
  PopperInner,
} from '@squadnet/frontend-web-ui';

import useAuthentication from '@/hooks/guards/useAuthenticationGuard';

const Authed = dynamic(() => import('../../UserMenu/Authed'), { ssr: false });

interface ICollapseNavItemProps {
  item: ITopNavigationLeftMenusDynamicZone | ITopNavigationRightMenusDynamicZone;
  className?: string;
  scrolled?: boolean;
  isTransparent?: boolean;
}
export const CollapseNavItem = ({ item, scrolled, isTransparent }: ICollapseNavItemProps) => {
  const { isAuthenticated } = useAuthentication();
  const pathname = usePathname();
  const it: any = item;
  if ((it?.authHidden || it?.profileReplace) && isAuthenticated) {
    if (it.authHidden) return null;
    if (it.profileReplace) {
      return (
        <div className='px-3'>
          <Authed scrolled={scrolled} isTransparent={isTransparent} />
        </div>
      );
    }
  }
  if (item.__typename === 'ComponentMoleculesMenuLink') {
    let Icon: any = undefined;
    if (item?.link?.icon?.data?.attributes) {
      Icon = props => <AtomsSvgIcon image={item?.link?.icon?.data?.attributes} {...props} />;
    }
    return (
      <NavigationLink
        linkTag={NextLink}
        href={item.link?.href || ''}
        prefetch={false}
        textClassName={clsx('text-md font-bold', isTransparent && !scrolled ? 'text-white-text' : '')}
      >
        <div className='inline-flex items-center'>
          {Icon ? (
            <div className='relative bottom-[-2px] mr-2'>
              <Icon size='sm' variant={isTransparent && !scrolled ? 'white' : 'mono'} />
            </div>
          ) : null}
          <span>{item.link?.text || item.name || ''}</span>
        </div>
      </NavigationLink>
    );
  }

  if (item.__typename === 'ComponentMoleculesMenuButton') {
    if (!item.button) return null;
    return <AtomsButton {...item.button} className='ml-3' />;
  }

  if (item.__typename === 'ComponentOrganismsMenuGroup') {
    return (
      <Popper
        reference={({ open }) => (
          <NavigationLink
            prefetch={false}
            selected={open || false}
            textClassName={clsx('text-md font-bold', isTransparent && !scrolled ? 'text-white-text' : '')}
          >
            <div className='inline-flex'>
              {item.title}
              <div className='ml-2 flex items-center'>
                <IconChevronDown
                  size='sm'
                  variant={open ? 'primary' : 'grey'}
                  className={clsx('transition-all', open ? 'rotate-180' : '')}
                />
              </div>
            </div>
          </NavigationLink>
        )}
      >
        {({ close }) => (
          <PopperInner className='!max-w-[100%]'>
            {item.id ? <OrganismsMenuGroup pathname={pathname} {...item} close={close} /> : null}
          </PopperInner>
        )}
      </Popper>
    );
  }
  return null;
};
