'use client';

import { ITopNavigationLeftMenusDynamicZone, ITopNavigationRightMenusDynamicZone } from '@squadnet/frontend-cms-gql';
import { useLocalOpen } from '@squadnet/frontend-shared-hooks';
import { Drawer, IconButton, IconMenu } from '@squadnet/frontend-web-ui';

import { MobileNavItem } from '../MobileNavItem';

interface IBurgerMenuProps {
  items?: Array<ITopNavigationLeftMenusDynamicZone | ITopNavigationRightMenusDynamicZone>;
  scrolled?: boolean;
  isTransparent?: boolean;
}
export const BurgerMenu = ({ items, isTransparent, scrolled }: IBurgerMenuProps) => {
  const { isOpen, open, close } = useLocalOpen();
  return (
    <>
      <IconButton Icon={IconMenu} iconVariant={isTransparent && !scrolled ? 'white' : undefined} onClick={open} />
      <Drawer open={isOpen} onClose={close}>
        <div className='grid gap-3 p-6'>
          {(items || []).map((item, index) => (
            <div key={index}>
              <MobileNavItem item={item} onClose={close} />
            </div>
          ))}
        </div>
      </Drawer>
    </>
  );
};
