'use client';

import { clsx } from 'clsx';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';

import { ITopNavigationLeftMenusDynamicZone, ITopNavigationRightMenusDynamicZone } from '@squadnet/frontend-cms-gql';
import {
  AtomsButton,
  Heading5,
  Heading6,
  Link,
  NavigationLink,
  serialize,
  SvgImage,
  Text,
  TextSmall,
} from '@squadnet/frontend-web-ui';

interface IMobileNavItemProps {
  item: ITopNavigationLeftMenusDynamicZone | ITopNavigationRightMenusDynamicZone;
  className?: string;
  onClose?: () => void;
}
export const MobileNavItem = ({ item, className, onClose }: IMobileNavItemProps) => {
  const pathname = usePathname();
  if (item.__typename === 'ComponentMoleculesMenuLink') {
    return (
      <NavigationLink
        linkTag={NextLink}
        href={item.link?.href || ''}
        prefetch={false}
        target={item.link?.target || undefined}
        onClick={onClose}
      >
        <Heading5 className={clsx('font-bold underline', className)} variant={item.link?.themedColor as any}>
          {item.link?.text || item.name || ''}
        </Heading5>
      </NavigationLink>
    );
  }

  if (item.__typename === 'ComponentMoleculesMenuButton') {
    if (!item.button) return null;
    return <AtomsButton onClick={onClose} {...item.button} />;
  }

  if (item.__typename === 'ComponentOrganismsMenuGroup') {
    return (
      <>
        <Heading5 className='!mb-0'>{item.title}</Heading5>
        {item.description ? serialize(item.description) : null}
        <div className='mt-4 grid w-full grid-cols-12 gap-5'>
          <div
            className={clsx(
              'col-span-12 grid grid-cols-1 gap-4 md:grid-cols-2',
              item.categories?.length ? 'md:col-span-8 lg:col-span-9' : '',
            )}
          >
            {(item.items || []).map(item => (
              <Link
                key={item?.id}
                className={clsx(
                  'inline-flex items-center rounded-md p-4',
                  item?.href ? 'cursor-pointer transition-all hover:scale-105' : '',
                  pathname === (item?.href || '...') ? 'bg-primary/10' : 'bg-background-shade',
                )}
                href={item?.href || undefined}
                onClick={onClose}
                target={item?.target || undefined}
              >
                {item?.icon?.data?.attributes?.url ? (
                  <div className='w-[32px]'>
                    <SvgImage src={item.icon.data.attributes.url} />
                  </div>
                ) : null}
                <div className='flex-1 pl-3'>
                  <Text className='font-bold'>{item?.title}</Text>
                  <TextSmall className='mt-1'>{item?.description}</TextSmall>
                </div>
              </Link>
            ))}
          </div>
          {item.categories?.length ? (
            <div className='col-span-12 md:col-span-4 lg:col-span-3'>
              {item.categoryTitle ? <Heading6>{item.categoryTitle}</Heading6> : null}
              {(item.categories || []).map(category => (
                <Link
                  className='pb-1'
                  href={category?.href || ''}
                  target={category?.target || undefined}
                  onClick={close}
                >
                  <TextSmall className='font-bold'>{category?.text}</TextSmall>
                </Link>
              ))}
            </div>
          ) : null}
        </div>
      </>
    );
  }
  return null;
};
