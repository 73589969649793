'use client';

import { clsx } from 'clsx';
import Img from 'next/image';
import NextLink from 'next/link';
import { useMemo } from 'react';

import { ITopNavigation, ITopNavigationLeftMenusDynamicZone } from '@squadnet/frontend-cms-gql';
import { PageNavigation, useMobileScreen } from '@squadnet/frontend-web-ui';

import { BurgerMenu } from '@/app/_ui/components/Cms/BurgerMenu';
import { CollapseNavItem } from '@/app/_ui/components/Cms/CollapseNavItem';
import { NavItem } from '@/app/_ui/components/Cms/NavItem';
import { useGetTopNavigation } from '@/hooks/useTopNavigation';

import blackLogo from '../../../../../../public/images/full_logo_black.png';
import whiteLogo from '../../../../../../public/images/full_logo_white.png';

interface LogoProps {
  scrolled?: boolean;
  black?: boolean;
  className?: string;
}
const Logo = ({ scrolled, black, className }: LogoProps) => {
  const isMobile = useMobileScreen();
  const logoSize = useMemo(() => {
    if (!isMobile) {
      return {
        width: 156,
        height: 20,
      };
    }
    return {
      width: 120,
      height: 16,
    };
  }, [isMobile]);

  return (
    <div {...logoSize} style={logoSize} className={clsx('relative', className)}>
      <div className={clsx('absolute transition-all', scrolled || black ? 'opacity-100' : 'opacity-0')}>
        <Img alt='logo' key={1} src={blackLogo} {...logoSize} />
      </div>
      <Img
        key={2}
        alt='logo'
        src={whiteLogo}
        {...logoSize}
        className={clsx('transition-all', scrolled || black ? 'opacity-0' : 'opacity-100')}
      />
    </div>
  );
};

interface NavProps {
  data: Partial<ITopNavigation>;
  isRelative?: boolean;
  isTransparent?: boolean;
  black?: boolean;
  animated?: boolean;
  seperator?: boolean;
}

type NavCollection = Array<ITopNavigationLeftMenusDynamicZone | ITopNavigationLeftMenusDynamicZone>;
export const TopNavigation = ({ data, animated, isRelative, isTransparent, black, seperator }: NavProps) => {
  const settings = useGetTopNavigation({
    isRelative,
    isTransparent,
    black,
  });
  const { topMenus, mobileMenus, hasTop } = useMemo(() => {
    const list = [
      ...(data.collapseLeft || []),
      ...(data.collapseRight || []),
      ...(data.rightMenus || []),
      ...(data.leftMenus || []),
    ] as NavCollection;
    return {
      hasTop: !!data.collapseLeft?.length || !!data.collapseRight?.length,
      topMenus: list.filter(x => (x as any).keepOnMobile),
      mobileMenus: list.sort((_, b) => (b.__typename === 'ComponentOrganismsMenuGroup' ? -1 : 1)),
    };
  }, [data]);
  return (
    <PageNavigation
      isTransparent={isTransparent}
      animated={animated}
      collapseTop={
        hasTop
          ? scrolled => (
              <>
                <div className='hidden w-full items-center justify-between md:inline-flex'>
                  <div className='inline-flex items-center'>
                    {(data.collapseLeft || []).map((menu, index) => (
                      <div key={index}>
                        <CollapseNavItem
                          item={menu as ITopNavigationLeftMenusDynamicZone}
                          isTransparent={settings.isTransparent}
                          scrolled={scrolled}
                        />
                      </div>
                    ))}
                  </div>
                  <div className='inline-flex items-center'>
                    {(data.collapseRight || []).map((menu, index) => (
                      <div key={index}>
                        <CollapseNavItem
                          item={menu as ITopNavigationLeftMenusDynamicZone}
                          isTransparent={settings.isTransparent}
                          scrolled={scrolled}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className='inline-flex items-center md:hidden'>
                  {topMenus.map((menu, index) => (
                    <div key={index}>
                      <CollapseNavItem
                        item={menu as ITopNavigationLeftMenusDynamicZone}
                        isTransparent={settings.isTransparent}
                        scrolled={scrolled}
                      />
                    </div>
                  ))}
                </div>
              </>
            )
          : undefined
      }
      left={scrolled => (
        <div className='inline-flex items-center'>
          <NextLink href='/' prefetch={false}>
            <Logo scrolled={scrolled} black={settings.black} className='mr-3 md:mr-6' />
          </NextLink>
          <div className='hidden items-center md:inline-flex'>
            {(data.leftMenus || []).map((menu, index) => (
              <div key={index}>
                <NavItem
                  item={menu as ITopNavigationLeftMenusDynamicZone}
                  isTransparent={settings.isTransparent}
                  scrolled={scrolled}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      collapseBreakpoint={46}
      classNames={{
        main: {
          default: settings.isRelative ? 'relative' : 'fixed',
          normal: settings.isTransparent ? '' : 'border-b-[1px] border-b-mono-3xlight',
        },
        inner: {
          normal: 'h-[90px]',
          collapse: 'h-[64px]',
        },
        top: {
          collapse: 'h-[0px] overflow-hidden opacity-0',
          normal: clsx(
            'h-[46px] border-b-[1px]',
            settings.isTransparent ? 'border-b-mono-light' : 'border-b-mono-3xlight',
          ),
        },
      }}
    >
      {scrolled => (
        <>
          <div className='hidden items-center md:inline-flex'>
            {seperator ? <div className='mx-4 h-[40px] w-[2px] rounded-md bg-mono-2xlight' /> : null}
            {(data.rightMenus || []).map((menu, index) => (
              <div key={index}>
                <NavItem
                  item={menu as ITopNavigationLeftMenusDynamicZone}
                  isTransparent={settings.isTransparent}
                  scrolled={scrolled}
                />
              </div>
            ))}
          </div>
          <div className='inline-flex items-center md:hidden'>
            <BurgerMenu items={mobileMenus} isTransparent={settings.isTransparent} scrolled={scrolled} />
          </div>
        </>
      )}
    </PageNavigation>
  );
};
